import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "@reach/router"
import MenuSVG from '../images/menu.svg'

const Header = ({ siteTitle, toggle, setIsOpen  }) => {

  return (
  <header
    style={{ background: "white" }}
    className="data-scroll-section flex flex-row justify-between w-full bg-white pt-3 px-5"
  >
    <div className="flex items-center cursor-pointer">
      <Link to="/">
        <StaticImage
          src="../images/werise-white.png"
          width={90}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Werise logo"
        />
      </Link>
    </div>
    <div onClick={() => setIsOpen(true)} className="flex xl:hidden items-center cursor-pointer">

        <MenuSVG />
    </div>
    <div className="hidden xl:flex flex-row items-center justify-between md:w-2/5">
      <span
        className="text-black font-medium	 cursor-pointer"
        onClick={() => navigate("/#about")}
      >
        About
      </span>
      <span
        className="text-black font-medium	 cursor-pointer"
        onClick={() => navigate("/#about")}
      >
        Features
      </span>
      <span
        className="text-black font-medium	 cursor-pointer"
        onClick={() => navigate("/candidate/")}
      >
        Candidate
      </span>
      <span
        className="text-black font-medium	 cursor-pointer"
        onClick={() => navigate("/voting-polls/")}
      >
        Voting Polls
      </span>
      <button
        className="btn bg-black px-6 py-3 rounded-md text-white btn--primary"
        onClick={() => navigate("/#contact")}
      >
        Contact us
      </button>
    </div>
  </header>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
