import { Link } from 'gatsby'
import React from 'react'
import Contact from '../images/contact.svg'
import Feature from '../images/features.svg'
import Group from '../images/group.svg'
import Cand from '../images/cand.svg'
import { LocationMarkerIcon } from '@heroicons/react/solid'

export const navItems = [
    {
        name: "About",
        href: "/#about",
        src: <Group />
    },
    {
        name: "Features",
        href: "/#features",
        src: <Feature />
    },
    {
        name: "Candidate",
        href: "/candidate",
        src: <Cand />
    },
    {
        name: "Voting Polls",
        href: "/voting-polls",
        src:
            <LocationMarkerIcon className='w-6 h-6' />

    },
    {
        name: "Contact",
        href: "/#contact",
        src: <Contact />
    },
]

export default function MobileNav({ isOpen, }) {

    return (
        <div className="mobile-nav__content">
            <div className="mt-16">
                {navItems && navItems.map(({ src, name, href }, idx) => (
                    <div className="px-10 hover:bg-gray-300 focus:bg-gray-300 focus:bg-opacity-50">

                        <Link to={href}>
                            <div key={idx} className={`flex my-4 py-5 text-orange
                    `}>
                                {/* ${activeLinkStyle === href && "active-link"} */}
                                <div className="flex fill-current justify-center ">
                                    {src}
                                </div>
                                <div
                                    className="cursor-pointer text-xl ml-5 text-orange">
                                    {name}
                                </div>
                            </div>
                        </Link>
                    </div>

                ))
                }
            </div>
        </div>
    )
}
