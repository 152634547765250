import * as React from "react"
import { useState } from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import MobileNav from "./MobileNav"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggle = () => {
    setModalOpen(!modalOpen)
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        {

          // <script
          // src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv53NEoMm3uPyA9U45ibSl3pOlqkHWN8&libraries=places&callback=initMap" async defer
          // />
        }

        {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv53NEoMm3uPyA9U45ibSl3pOlqkHWN8&callback=initMap" async defer /> */}

      </Helmet>
      <div
        style={{
          display: modalOpen ? "block" : "none"
        }}
        onClick={toggle}
        className="mobile-sidebar-overlay"
      ></div>
      <div
        className={`mobile-sidebar`}
        style={{
          display: modalOpen ? "block" : "none"
        }}
      >
        <MobileNav isOpen={modalOpen} />
      </div>

      <Header toggle={toggle} setIsOpen={setModalOpen} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
